
<template>
  <div class="move-tabs">
    <div class="tabs-wrapper" ref='tabsWrapper'>
      <div ref='tab' class="tab">
        <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_ALL)"
             :class="{'active':$store.state.selectedGameType == sportsConst.GAME_TYPE_ALL}">
          <div class='expand-block'>
            <span class="ebimg">
              <img src="../../../../assets/images/icon/type/1/all.svg" alt="">
            </span>
            <span class="ebtext">
               전체
            </span>
          </div>
        </div>
        <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_SOCCER)"
             :class="{'active':$store.state.selectedGameType == sportsConst.GAME_TYPE_SOCCER}">
          <div class='expand-block'>
            <span class="ebimg">
              <img src="../../../../assets/images/icon/type/1/football-ico.png" alt="">
            </span>
            <span class="ebtext">
               축구
            </span>
          </div>

        </div>

        <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_BASKETBALL)"
             :class="{'active':$store.state.selectedGameType == sportsConst.GAME_TYPE_BASKETBALL}">
          <div class='expand-block'>
            <span class="ebimg">
              <img src="../../../../assets/images/icon/type/1/basketball-ico.png" alt="">
            </span>
            <span class="ebtext">
               농구
            </span>
          </div>
        </div>

        <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_BASEBALL)"
             :class="{'active':$store.state.selectedGameType == sportsConst.GAME_TYPE_BASEBALL}">
          <div class='expand-block'>
            <span class="ebimg">
              <img src="../../../../assets/images/icon/type/1/baseball-ico.png" alt="">
            </span>
            <span class="ebtext">
               야구
            </span>
          </div>
        </div>

        <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_VOLLEYBALL)"
             :class="{'active':$store.state.selectedGameType == sportsConst.GAME_TYPE_VOLLEYBALL}">
          <div class='expand-block'>
            <span class="ebimg">
              <img src="../../../../assets/images/icon/type/1/volleyball-ico.png" alt="">
            </span>
            <span class="ebtext">
               배구
            </span>
          </div>
        </div>

        <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_HOCKEY)"
             :class="{'active':$store.state.selectedGameType == sportsConst.GAME_TYPE_HOCKEY}">
          <div class='expand-block'>
            <span class="ebimg">
              <img src="../../../../assets/images/icon/type/1/hockey-ico.png" alt="">
            </span>
            <span class="ebtext">
               하키
            </span>
          </div>
        </div>

        <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_USA_FOOTBALL)"
             :class="{'active':$store.state.selectedGameType == sportsConst.GAME_TYPE_USA_FOOTBALL}">
          <div class='expand-block'>
            <span class="ebimg">
              <img src="../../../../assets/images/icon/type/1/usa-football-ico.png" alt="">
            </span>
            <span class="ebtext">
               미식축구
            </span>
          </div>

        </div>
        <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_ESPORTS)"
             :class="{'active':$store.state.selectedGameType == sportsConst.GAME_TYPE_ESPORTS}">
          <div class='expand-block'>
            <span class="ebimg">
              <img src="../../../../assets/images/icon/type/1/esport-ico.png" alt="">
            </span>
            <span class="ebtext">
               이스포츠
            </span>
          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script>
import sportsConst from "@/common/sportsConst";
import BScroll from 'better-scroll'
export default {
  name: "SportsGameTypeSelector",
  data() {
    return {
      sportsConst,
      tabs: ['전체', '축구', '농구', '야구', '배구', '하키', 'eports', '미식축구'],
      mX: 0,
      tabWidth: 12.5,
      selected: sportsConst.GAME_TYPE_ALL,
    }
  },
  methods: {
    selectedType(type) {
      this.selected = type
      this.$store.state.selectedGameType = type
    },
    _initMenu() {
      // const tabsWidth = this.tabWidth
      // const width = 8 * tabsWidth
      // this.$refs.tab.style.width = `${width}%`
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.tabsWrapper, {
            scrollX: true,
            eventPassthrough: 'vertical',
          })
        } else {
          this.scroll.refresh()
        }
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      //this._initMenu()
    })
  },
}
</script>


<style scoped>
.move-tabs {
  position: relative;
  width: 100%;
  background-color: #0c0c0e;
  box-sizing: border-box;
  padding: .5rem;
}

.tabs-wrapper {
  height: 6rem;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  background-color: #2a2e39;
  border-radius: .5rem;
  display: flex;
  align-items: center;
}

.tab {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.tab-item {
  flex: 1;
  --width: 12.2%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: .5rem;
  transition: 0.8s transform;
  padding: .2rem 0;
  animation: zoomIn;
  animation-duration: .3s;

}

.expand-block {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.expand-block .ebimg img{
  width: 2rem;
}
.expand-block .ebtext {
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2rem;
}

.active {
  color: #ffff00 !important;
  font-weight: bold;
  --font-style: italic;
  background: linear-gradient(180deg, #969696, #969696);
  animation: pulse;
  animation-duration: .5s;
}

@media screen and (max-width: 1024px) {
  .move-tabs{
    position: static!important;
  }
  .tab {

  }
  .expand-block .ebtext {
    color: #ffffff;
    font-weight: normal;
    font-size: 1rem;
  }

  .tab-item {
    --width: 12.1% !important;
  }
}

</style>